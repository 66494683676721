@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300&family=Source+Sans+Pro&display=swap");

:root {
  --black:    #000000;
  --black90:  #171717;
  --black80:  #2f2f2f;
  --black70:  #474747;
  --black60:  #606060;
  --black50:  #797979;
  --black40:  #939393;
  --black30:  #acacac;
  --black20:  #c8c8c8;
  --black10:  #e3e3e3;
	--black05:  #f2f2f2;
  --white:    #ffffff;
  --green:    #008800;
	--green75:	#40a640;
  --red:      #cc3300;
	--blue:			#408cd8;
	--blue75:		#7fb2e5;
	--gold:			#c3a634;
	--gold25:		#efe8c9;
	--gold10:		#f9f6e9;
}

.black    { color: var(--black) !important; }
.black90  { color: var(--black90) !important; }
.black80  { color: var(--black80) !important; }
.black70  { color: var(--black70) !important; }
.black60  { color: var(--black60) !important; }
.black50  { color: var(--black50) !important; }
.black40  { color: var(--black40) !important; }
.black30  { color: var(--black30) !important; }
.black20  { color: var(--black20) !important; }
.black10  { color: var(--black10) !important; }
.black05  { color: var(--black05) !important; }
.white    { color: var(--white) !important; }
.green    { color: var(--green) !important; }
.green75  { color: var(--green75) !important; }
.red      { color: var(--red) !important; }
.blue     { color: var(--blue) !important; }
.blue75   { color: var(--blue75) !important; }
.gold			{ color: var(--gold) !important; }
.gold25		{ color: var(--gold25) !important; }
.gold10		{ color: var(--gold1) !important; }

body {
  font-family: "Source Sans Pro", sans-serif;
	font-weight: normal;
	color: var(--black60);
  line-height: 1.6;
	font-size: .9rem;
  background-color: var(--white);
}

/* CONTAINER */

.container {
	max-width: 71.25rem; /* Set to 1140px (71.25rem); Bootstrap 5 default is 1320px (82.5rem) */
}

/* NAVBAR */

.navbar {
  color: var(--white);
  background-color: var(--gold) !important;
}

.navbar a {
  color: var(--white) !important;
}

.navbar-toggler {
	border: none !important;
}

.navbar-toggler-icon {
	background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 2)' stroke-width='2' stroke-linecap='square' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler:focus {
	text-decoration: none;
	outline: 0;
	box-shadow: none;
}

/* MAIN */

main {
  margin: 6rem 0 0 0;
  height: 100% !important;
}

section {
  background-color: var(--gold10) !important;
	padding: 1rem 0 3rem 0;
	margin-top: 1.5rem;
}

h1, h2 {
	font-size: 1.5rem;
	padding: 1rem 0 1rem 0;
}

table {
	font-size: .75rem !important;
}

footer {
  color: var(--white);
  background-color: var(--gold);
  padding: 2rem 0;
  font-size: .9rem;
  text-align: center;
}

footer a {
  color: var(--white);
  text-decoration: none;
}

footer a:hover {
  color: var(--white);
}

blockquote {
	padding: 0 2rem 0 2rem;
}

canvas {
	margin: 2rem 0;
}

.lead {
	font-size: 1.2rem;
	font-style: italic;
}

/* LINKS */

a.tx-url {
	color: var(--blue) !important;
	text-decoration: none;
}

a.tx-url:hover {
	color: var(--blue75) !important;
	text-decoration: none;
}

/* MISC */

.box-tips {
	text-align: center;
	background-color: var(--gold10) !important;
	border: .1rem solid var(--black20);
	padding: .3rem .3rem;
	margin-bottom: 2rem;
	overflow: hidden;
}

.box-sp {
	font-size: .8rem;
	border: .1rem solid var(--black20);
	border-left: .3rem solid var(--gold);
	padding: .3rem .3rem;
	margin-bottom: 1rem;
	overflow: hidden;
}

#tx-box {
	font-family: "Source Code Pro", monospace;
	font-weight: 300;
	font-size: .75rem;
	line-height: 1.25rem;
	color: var(--black10);
	background-color: var(--black80);
	overflow-x: hidden !important;
	height: 420px;
	padding: .5rem 1rem;
	margin-bottom: 1rem;
}

.sans-serif {
	font-family: "Source Code Pro", sans-serif;
}

.strong {
	font-weight: bold;
}

.list-group {
	font-size: .8rem;
	padding: 0 .75rem;
	border-radius: 0 !important;
}

.list-group-item {
	width: 20%;
	padding: .3rem .3rem !important;
	border: .1rem solid var(--black20);
	border-left: .3rem solid var(--gold);
	white-space: nowrap;
	color: var(--black60);
}

.list-group-horizontal > .list-group-item:first-child,
.list-group-horizontal > .list-group-item:last-child {
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.chart-left {
	padding-right: 1rem;
}

.chart-right {
	padding-left: 1rem;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.pl {
	padding-left: .3rem;
}

.pb {
	padding-bottom: 1.3rem;
}

.border {
	border: .1rem solid red;
}

.border-left {
	border-left: .3rem solid red;
}

.border-right {
	border-right: .3rem solid red;
}

.d-sm-none {
	display: none !important;
}

#load {
	text-align: center; 
	margin-top: 9rem;
}

/* FADEIN */

.fadein {
	animation: fadein 1s;
}

.fadein {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@-o-keyframes fadeIn { 
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* FADEIN/OUT ANIMATION */

#loading {
	-webkit-animation: fadeIn 1s infinite alternate;
     -moz-animation: fadeIn 1s infinite alternate;
      -ms-animation: fadeIn 1s infinite alternate;
       -o-animation: fadeIn 1s infinite alternate;
          animation: fadeIn 1s infinite alternate;
}

@keyframes fadeIn { 
  from { opacity: .25; } 
}

@-o-keyframes fadeIn { 
  from { opacity: .25; } 
}

@-moz-keyframes fadeIn { 
  from { opacity: .25; } 
}

@-webkit-keyframes fadeIn { 
  from { opacity: .25; } 
}

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: .5rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--black10); 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--black30);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--black50); 
}

/* ADS */

.ad-container {
	width: 728px;
	height: 90px;
  display: grid;
  grid-template-columns: 1fr fit-content(20%);
  margin: 0 auto;
}

.ad-horizontal {
  width: 728px;
	height: 90px;
   margin: 5rem auto 0 auto;
}

.ad-horizontal-bottom {
  width: 728px;
	height: 90px;
  margin: 1rem auto 0 auto;
}

/* MEDIA QUERIES */

@media (min-width: 768px) {
	.d-md-block {
		display: block !important;
	}
}

@media (max-width: 767px) {
	.chart-left {
		padding-right: 0;
	}

	.chart-right {
		padding-left: 0;
	}

	.hide-sm {
		display: none !important;
	}

	.ad-container {
		width: 320px;
		height: 100px;
		display: grid;
		grid-template-columns: 1fr fit-content(20%);
		margin: 0 auto;
	}

	.ad-horizontal {
		width: 320px;
		height: 100px;
		margin: 5rem auto 0 auto;
	}

	.ad-horizontal-bottom {
		width: 320px;
		height: 100px;
		margin: 1rem auto 0 auto;
	}
}

